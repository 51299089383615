import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-wichita-kansas.png'
import image0 from "../../images/cities/scale-model-of-botanica,-the-wichita-gardens-in-wichita-kansas.png"
import image1 from "../../images/cities/scale-model-of-museum-of-world-treasures-in-wichita-kansas.png"
import image2 from "../../images/cities/scale-model-of-sedgwick-county-zoo-in-wichita-kansas.png"
import image3 from "../../images/cities/scale-model-of-wichita-sedgwick-county-historical-museum-in-wichita-kansas.png"
import image4 from "../../images/cities/scale-model-of-ulrich-museum-of-art-in-wichita-kansas.png"
import image5 from "../../images/cities/scale-model-of-visit-wichita-in-wichita-kansas.png"
import image6 from "../../images/cities/scale-model-of-certified-folder-display-service,-inc.-in-wichita-kansas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Wichita'
            state='Kansas'
            image={image}
            lat='37.68717609999999'
            lon='-97.33005300000002'
            attractions={ [{"name": "Botanica, The Wichita Gardens", "vicinity": "701 Amidon St, Wichita", "types": ["park", "point_of_interest", "establishment"], "lat": 37.6963714, "lng": -97.3637766}, {"name": "Museum of World Treasures", "vicinity": "835 E 1st St N, Wichita", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.687089, "lng": -97.32788499999998}, {"name": "Sedgwick County Zoo", "vicinity": "5555 W Zoo Blvd, Wichita", "types": ["zoo", "point_of_interest", "establishment"], "lat": 37.717034, "lng": -97.40856099999996}, {"name": "Wichita-Sedgwick County Historical Museum", "vicinity": "204 S Main St, Wichita", "types": ["travel_agency", "museum", "point_of_interest", "establishment"], "lat": 37.6847276, "lng": -97.33785899999998}, {"name": "Ulrich Museum of Art", "vicinity": "Wichita State University, 1845 Fairmount Street, Wichita", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.7170198, "lng": -97.29644100000002}, {"name": "Visit Wichita", "vicinity": "515 S Main St # 115, Wichita", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 37.6809532, "lng": -97.33849459999999}, {"name": "Certified Folder Display Service, Inc.", "vicinity": "1722 E 2nd St N #4, Wichita", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 37.690342, "lng": -97.31645300000002}] }
            attractionImages={ {"Botanica, The Wichita Gardens":image0,"Museum of World Treasures":image1,"Sedgwick County Zoo":image2,"Wichita-Sedgwick County Historical Museum":image3,"Ulrich Museum of Art":image4,"Visit Wichita":image5,"Certified Folder Display Service, Inc.":image6,} }
       />);
  }
}